<template lang="pug">
v-row.wrap(no-gutters)
  v-flex(xs6 md3): t-btncard(color="primary" icon="mdi-account-plus" Zto="/template") Add customer
  v-flex(xs6 md3): t-btncard(color="primary" icon="mdi-plus-box-multiple" Zto="/template") Add custom sale
  v-flex(xs6 md3): t-btncard(icon="mdi-truck" flat) Ship to customer
  v-flex(xs6 md3): t-btncard(color="amber" icon="mdi-link") COMRUN.co
  v-flex(xs6 md3): t-btncard(color="amber" icon-color="red"  icon="mdi-devices") POS Smart Grid
  v-flex(xs6 md3): t-btncard(icon="mdi-plus" outlined dark color="primary") Add tile
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  components: {
  },

  // mixins: [decimalMixin, dictionaryMixin, billsAndCoinsMixin],

  props: {
    item: {
      type: Object,
      default: null,
      required: false,
    },
    preNewItem: {
      type: Object,
      default: null,
      required: false,
    },
  },

  data: () => ({
    showDialog: false,
    editMode: false,
    discountMarkupValue: 0,
    positionsDialog: false,
  }),

  computed: {
    ...mapState({
      positionsLoading: (state) => state.positions.loading,
    }),
  },

  watch: {},

  methods: {
    ...mapActions({
    }),
  },
}
</script>
<style lang="stylus"></style>
